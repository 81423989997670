@import 'assets/styles/_variables';

.form-message {
  color: $cl-red;

  &--general {
    margin-top: $spacing-tiny;
    font-size: $font-size-small;
  }

  &--below-field {
    margin-top: $spacing-tiny;
    margin-bottom: 0;
    padding: 0 $spacing-small;
  }
}
