@import 'assets/styles/_variables.scss';

.layout-block {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $spacing-large;
  width: 100%;

  > * {
    width: 100%;
  }

  &--inline {
    justify-content: initial;

    & > * {
      margin-right: $spacing;
      width: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--split > * {
    width: calc(50% - #{$spacing});
    box-sizing: border-box;
  }

  &--split-inline > * {
    width: auto;
  }

  &--no-spacing {
    margin: 0;
  }

  &--end {
    align-self: flex-end;
    height: 100%;
    margin-bottom: $spacing-huge * -1;
    padding-bottom: $spacing-huge;
  }

  &--align-right {
    justify-content: flex-end;
  }
}