@import 'assets/styles/_variables';

.user-role-rights {
  &__groups {

    &-heading {
      margin: $spacing-large 0 10px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__section {
      border-bottom: 2px solid #CBD5D6;
      display: flex;
      flex-direction: row;
      padding: 12px 0;

      &-name {
        flex-grow: 1;
        line-height: 28px;
      }

      &-checkboxes {
        width: 300px;
        display: flex;
      }

      &-checkbox {
        flex-grow: 1;
      }
    }
  }
}
