@import 'assets/styles/_variables';

.text--small {
  font-size: 14px;
}

.text--normal {
  font-size: $font-size-normal;
  line-height: 22px;
}

.text--x-large {
  font-size: $font-size-xlarge;
}

.text--color-grey-dark {
  color: $cl-grey-dark
}

.text--color-grey-black {
  color: rgba($cl-grey-black, .5);
}

.text--flatten {
  margin-bottom: 0;
}

.text--no-top {
  margin-top: 0;
}