@import 'assets/styles/_variables';

.form__checkbox {
  cursor: pointer;
  line-height: 20px;

  &__check {
    border: 2px solid $cl-grey-border;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    height: 20px;
    margin: 0 10px -4px 0;
    width: 20px;

    &::before {
      background: transparent;
      border-radius: 2px;
      content: '';
      display: block;
      height: 12px;
      margin: 2px;
      transition: background .2s ease;
      width: 12px;
    }
  }

  &__label {
    color: $cl-blue;
    display: inline-block;

    &--black {
      color: $cl-grey-black;
    }
  }

  @include hover {
    .form__checkbox__check {
      &::before {
        background-color: $cl-grey-border;
      }
    }
  }

  input {
    display: none;

    &:checked + .form__checkbox__check {
      &::before {
        background-color: $cl-blue;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    @include hover {
      .form__checkbox__check {
        &::before {
          background-color: $cl-white;
        }
      }
    }

    input:checked + .form__checkbox__check::before
    {
      background-color: $cl-grey-border;
    }

    .form__checkbox__label {
      color: $cl-grey-disabled;
    }
  }

  &--green {
    line-height: 28px;

    .form__checkbox__check {
      background: $cl-grey-border;
      border: 4px solid $cl-white;
      border-radius: 6px;
      box-shadow: 0px 4px 10px rgba($cl-black, .1);
      height: 28px;
      margin-bottom: -8px;
      position: relative;
      width: 28px;

      &::before {
        background-blend-mode: multiply;
        border-radius: 3px;
        height: 20px;
        margin: 0px;
        width: 20px;
      }

      &::after {
        background-color: transparent;
        background-size: contain;
        content: '';
        display: block;
        height: 12px;
        margin: 4px;
        mask: url('/icons/icon_check.svg') no-repeat center;
        mask-size: 12px;
        position: absolute;
        transition: background-color .1s ease;
        top: 0;
        width: 12px;
      }
    }

    input {
      &:checked + .form__checkbox__check {
        &::before {
          background-color: $cl-green;
          background-image: linear-gradient(rgba($cl-white, .0), rgba($cl-black, .1));
        }

        &::after {
          background-color: $cl-white;
        }
      }
    }

    &.form__checkbox--disabled {
      input:checked + .form__checkbox__check,
      .form__checkbox__check {
        &::before
        {
          background-color: $cl-grey-border;
          background-image: none;
        }
      }
    }

    @include hover {
      .form__checkbox__check {
        &::after {
          background-color: $cl-white;
        }
      }
    }
  }
}