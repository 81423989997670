@import 'assets/styles/_variables';

.profile-page{
  &__title {
    margin-top: 80px;
    margin-bottom: 50px;
  }

  &__card-layout {
    display: grid;
    grid-gap: $spacing;
    grid-template-areas:
            "general general"
            "permissions logout";
    grid-template-columns: 1fr 1fr;
  }

  &__general-card {
    grid-area: general;

    &-layout {
      display: grid;
      grid-gap: 50px;
      grid-template-areas:
              "name email"
              "username password";
    }

    &-name {
      grid-area: name;
    }

    &-username {
      grid-area: username;
    }

    &-email {
      grid-area: email;
    }

    &-password {
      grid-area: password;
    }
  }

  &__permissions-card {
    grid-area: permissions;
  }

  &__logout-card {
    grid-area: logout;
  }

}