@import 'assets/styles/_variables';

.two-factor-page__fields-container {
  margin-top: 80px;
  background-color: $cl-white;
  border-radius: 6px;
  box-shadow: 0px 6px 10px -2px rgba($cl-black, .3);
  text-align: center;
  padding: 30px 40px;
}

.two-factor-page__icon {
  margin: 30px auto;
  display: block;
}

.two-factor-page__login-button {
  margin-top: 20px;
}