@import "assets/styles/_variables";

.form__dropdown {
  width: 100%;

  & &__control {
    background: $cl-white;
    border-radius: 6px;
    cursor: pointer;
    height: $spacing-huge;
    padding: 0 0 0 $spacing-small;

    &--menu-is-open {
      border-radius: 6px 6px 0 0;
    }

    &--is-disabled {
      box-shadow: none;
    }
  }

  &--add {
    .form__dropdown__control {
      border: 0;
      box-shadow: 0 4px 10px -2px rgba($cl-black, 0.3);

      @include hover {
        box-shadow: 0 4px 10px -2px rgba($cl-black, 0.6);
      }

      &--is-disabled {
        box-shadow: none;

        @include hover {
          box-shadow: none;
        }
      }

      &--menu-is-open {
        box-shadow: 0 6px 10px -2px rgba($cl-black, 0.6);

        @include hover {
          box-shadow: 0 6px 10px -2px rgba($cl-black, 0.6);
        }
      }
    }

    .form__dropdown__menu {
      border: 0;
      border-top: 2px solid $cl-grey-bg;
      box-shadow: 0 6px 10px -2px rgba($cl-black, 0.6);
    }
  }

  &--navigate {
    .form__dropdown__control {
      border: 2px solid $cl-grey-border;
      box-shadow: none;
      background-color: transparent;
      color: $cl-black;

      @include hover {
        border: 2px solid $cl-grey-border;
        box-shadow: none;
      }
    }

    .form__dropdown__menu {
      box-shadow: none;
      border: 2px solid $cl-grey-border;
      border-top: 0;
    }
  }

  &--small {
    .form__dropdown__control {
      height: 42px;
    }

    .form__dropdown__container .icon {
      right: 16px;
      top: 17px;
    }
  }

  &__label {
    display: inline-block;

    &--block {
      display: block;
    }
  }

  &__icon {
    &--add {
      color: $cl-blue;
    }

    &--navigate {
      color: $cl-purple;
    }

    &--disabled {
      color: $cl-grey-disabled;
    }
  }

  &__menu-block {
    > *:last-child {
      margin-left: $spacing;
    }
  }

  &__container {
    display: block;
    position: relative;
    width: 100%;

    .icon {
      pointer-events: none;
      position: absolute;
      right: $spacing;
      top: $spacing;
    }
    &--disabled {
      cursor: not-allowed;

      .icon {
        color: $cl-grey-disabled;
      }
    }
  }

  &__single-value {
    padding-right: $spacing;
  }

  & &__control {
    position: relative;
  }

  & &__value-container {
    padding: 0;
  }

  & &__menu {
    box-sizing: border-box;
    border-radius: 0 0 6px 6px;
    margin: 0;
    z-index: 10;
  }

  & &__menu-list {
    padding: $spacing-tiny 0;
  }

  & &__option {
    padding: 10px $spacing-small;
    cursor: pointer;

    &--is-focused,
    &--is-selected {
      background: none;
      color: $cl-blue;
    }

    @include hover {
      background: none;
      color: $cl-blue;
    }
  }

  &__multi-value {
    &--is-disabled {
      &:before {
        color: $cl-grey-disabled;
      }

      .form__dropdown__multi-value__label {
        color: $cl-grey-disabled;
      }
    }
  }

  &--is-disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  &--changed {
    .form__dropdown__control {
      border: 2px solid $cl-orange;

      @include hover {
        border: 2px solid $cl-orange;
      }
    }
  }
}
