@import 'assets/styles/_variables';

.form__input {
  background: none;
  border: none;
  border-bottom: 2px solid $cl-grey-medium;
  box-sizing: border-box;
  color: $cl-grey-dark;
  display: block;
  line-height: 50px;
  outline: none;
  padding: 0;
  transition: opacity;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &[type=number] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
      margin: 0;
    }
  }

  @include hover {
    color: $cl-grey-black;
  }

  &[disabled] {
    border-color: $cl-grey-disabled;
    box-shadow: none;
    color: $cl-grey-disabled;
    -webkit-text-fill-color: $cl-grey-disabled;
    cursor: not-allowed;
  }

  &__container {
    position: relative;
  }

  &__prefix {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  &__suffix {
    align-items: center;
    bottom: 2px;
    display: flex;
    height: 46px;
    position: absolute;
    right: 0;
    color: $cl-sigma-cool-blue;
    padding-right: $spacing-small;

    &--disabled {
      color: $cl-grey-disabled;
      cursor: not-allowed;
    }
  }

  &::placeholder {
    color: rgba($cl-grey-black, .5);
  }

  &--font-large {
    font-size: $font-size-huge;
    font-weight: 500;
  }

  &--large {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &--white {
    background: $cl-white;
    border: none;
    border-radius: 6px;
    box-shadow: 0 6px 10px -2px rgba($cl-black, .3);
    padding-left: $spacing-small;
    padding-right: $spacing-small;
    position: relative;

    &.form__input--changed {
      border: 2px solid $cl-orange;
      line-height: 46px;
    }
  }

  &--prefix {
    padding-left: 40px;
  }

  &--login {
    border: none;
    padding-left: $spacing-small;
    padding-right: $spacing-small;
    position: relative;
  }

  &--error {
    border-bottom-color: $cl-red;
  }

  &--changed {
    &, &[disabled] {
      border-bottom: 2px solid $cl-orange;
    }
  }
}
