.breadcrumbs {
  margin-bottom: 15px;
}

.breadcrumb__link {
  color: #323333;
  opacity: .5;

  &:after {
    content: '/';
    margin: 0 5px;
  }
}