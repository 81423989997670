@import 'assets/styles/_variables';

.form__datetime {
  background: $cl-white;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 10px -2px rgba($cl-black, .3);
  box-sizing: border-box;
  color: $cl-black;
  height: 50px;
  outline: none;
  padding: 0 16px;
  transition: box-shadow .2 ease;
  width: 155px;

  &:not([disabled]) {
    @include hover {
      box-shadow: 0px 4px 10px -2px rgba($cl-black, .6);
    }
  }

  .MuiInputBase-root {
    height: 50px;
  }

  .MuiInputBase-input {
    padding-left: 16px;
  }

  .MuiInput-underline {
    &:before {
      border: 0 !important;
    }

    &:after {
      display: none;
    }
  }

  .Mui-error {
    &:before {
      border-radius: 0 1px 1px 0;
      bottom: 12px;
      background: #D0021B;
      left: 0;
      position: absolute;
      top: 12px;
      width: 2px;
    }
  }

  &--time {
    width: 112px;
  }

  &__container {
    display: inline-block;
    position: relative;
  }

  &--disabled {
    color: $cl-grey-disabled;
    border-color: $cl-grey-disabled;
    cursor: not-allowed;
  }

  &--changed {
    border: 2px solid $cl-orange !important;
    line-height: 46px;
  }

  &__icon {
    color: $cl-blue;

    &--disabled {
      color: $cl-grey-disabled;
    }
  }
}

// datepicker component
.react-datepicker-popper {
  z-index: 2;
}