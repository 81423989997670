@import 'assets/styles/_variables';

.rdw-editor-toolbar {
  justify-content: flex-end;
}

.rdw-fontfamily-wrapper {
  margin-right: auto;
  margin-bottom: 0;
  flex-grow: 2;
}

.rdw-block-dropdown {
  width: unset;
}

.rdw-history-wrapper,
.rdw-text-align-wrapper,
.rdw-list-wrapper,
.rdw-block-wrapper,
.rdw-inline-wrapper {
  margin: 0;
}

.rdw-dropdown-wrapper {
  background: none;
  height: 40px;
  margin: 0;

  &:hover {
    box-shadow: none;
    background: none;
  }
}

.rdw-dropdown-carettoclose,
.rdw-dropdown-carettoopen {
  top: 40%;
  right: 10px;
}

.rdw-center-aligned-block > .public-DraftStyleDefault-block > span {
  text-align: center;
  width: 100%;
  display: block;
}

.rdw-right-aligned-block > .public-DraftStyleDefault-block > span {
  text-align: right;
  width: 100%;
  display: block;
}

.rich-text-editor {
  border-radius: 10px;
  box-shadow: 0px 4px 10px -2px rgba($cl-black, .3);

  &__icon{
    img {
      background-color: black;
      width: 24px;
      height: 24px;
    }

    // TODO: copy icons to public folder on compile
    &-bold {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_bold.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }

    &-italic {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_italic.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }

    &-underline {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_underline.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }

    &-strikethrough {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_strikethrough.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }

    &-ordered-list {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_ordered-list.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }

    &-unordered-list {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_unordered-list.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }

    &-undo {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_undo.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }

    &-redo {
      img {
        mask: url(../../../../src/assets/icons/icon_editor_redo.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }
  }

  &__wrapper {
    display: inline-block;
    border-radius: 10px;
    height: 400px;
    width: 100%;
  }

  &__toolbar {
    background: $cl-grey-bg;
    border-radius: 5px;
    box-shadow: 0px 4px 10px -2px rgba($cl-black, .3);
    margin: 10px;
    border: 0;
    padding: 0;

    &__font-family {
      width: 100%;
    }

    &__block-type {
      border-left: 1px solid $cl-grey-border;
      width: 100px;
    }
  }

  &__editor {
    padding: 20px;
    height: 100%;
    max-height: 328px;
    position: relative;
  }
}

.rdw-option-wrapper {
  height: 30px;
  border-left: 1px solid $cl-grey-border;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  margin: 0;
  background: unset;

  &:hover {
    box-shadow: none;
  }
}

.rdw-text-align-dropdown {
  border-left: 1px solid $cl-grey-border;
}

.rdw-option-active {
  box-shadow: none;

  img {
    background-color: $cl-blue;
  }
}

.rdw-option-disabled {
  opacity: 1;

  img {
    opacity: 0.3;
  }
}

.rdw-fontfamily-placeholder {
  max-width: 190px;
}

.rdw-text-align-dropdown {
  width: 58px;
}

.DraftEditor-editorContainer {
  border: 0;
}

.DraftEditor-root {
  height: 100%;
  max-height: 328px;
  overflow-y: auto;
  position: unset;
}

.public-DraftStyleDefault-block {
  margin: 0;
  width: 100%;
}

.public-DraftEditor-content {
  height: 100%;
}