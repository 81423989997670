@import 'assets/styles/_variables';

.label {
  display: block;
  margin-bottom: $spacing-tiny;

  .icon {
    color: $cl-blue;
    margin-right: 6px;

    &--small {
      margin-bottom: -4px;
    }
  }

  &--grey {
    color: rgba($cl-grey-black, .5);;
  }

  &--black {
    color: $cl-grey-black;
  }
}