@import 'assets/styles/_variables';

.overview-list-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: $cl-grey-black;
  border-radius: 4px;
  margin-bottom: $spacing-tiny;
  padding: $spacing-small $spacing $spacing-small $spacing - 4px;

  &--loading {
    border-left: 4px solid $cl-white;
  }

  &--status {
    &-default {
      border-left: 4px solid $cl-white;
    }

    &-pending {
      border-left: 4px solid $cl-orange;
    }

    &-active {
      border-left: 4px solid $cl-blue;
    }
  }

  &--loaded {
    transition: .2s ease;
    transition-property: border, box-shadow, margin, padding;

    @include hover {
      border-left-width: 10px;
      border-radius: 6px;
      box-shadow: 0 6px 10px -2px rgba($cl-black, .6);
      margin: 4px -6px 8px -6px;
      padding-bottom: $spacing;
      padding-right: $spacing + 6px;
      padding-top: $spacing;
    }

    li:first-child {
      .overview-list__list-item {
        @include hover {
          margin-top: 0;
        }
      }
    }

    li:last-child {
      .overview-list__list-item {
        @include hover {
          margin-bottom: 0;
        }
      }
    }
  }

  &__column {
    line-height: unset;
    flex-grow: 1;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    &--status {
      &-disabled {
        opacity: 0.4;
      }
    }
  }
}