@use "sass:math";
@import 'assets/styles/_variables';

$skeleton-shine-size: 120px;
$skeleton-color: rgba($cl-grey-border, .7);

.skeleton {
  border-radius: 2px;
  color: transparent;
  user-select: none;
  width: fit-content;
  max-width: 100%;
  background-color: $skeleton-color;
  background-image: linear-gradient(110deg, rgba($cl-grey-light, 0) 0, rgba($cl-grey-light, 1) math.div($skeleton-shine-size, 2), rgba($cl-grey-light, 0) $skeleton-shine-size);
  background-repeat: no-repeat;
  animation: loading 2500ms infinite;
  overflow: hidden;
}

@keyframes loading {
  from {
    background-color: lighten($skeleton-color, 1);
    background-position: -1 * $skeleton-shine-size 0;
  }
  30% {
    background-position: -1 * $skeleton-shine-size 0;
    background-color: $skeleton-color;
  }
  to {
    background-position: 100vw 0;
    background-color: lighten($skeleton-color, 1);
  }
}