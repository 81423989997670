@import 'assets/styles/_variables';

.overview-list {
  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    opacity: .5;
    padding: 0 $spacing $spacing-small;

    &-column {
      flex-grow: 1;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__list {
    a {
      text-decoration: none;
    }

    button {
      width: 100%;
    }
  }
}