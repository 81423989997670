@import 'assets/styles/_variables';

.media-card-folder {
  padding: $spacing-small $spacing;
}

.media-card-folder__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media-card-folder__heading {
  margin: 0;
  font-weight: 500;
}

.media-card-folder__label {
  display: flex;
  align-items: center;
}

.media-card-folder__icon {
  margin-right: 15px;
  color: $cl-grey-border;
  position: relative;
  width: 18px;
}

.media-card-folder__hover {
  opacity: 0;
  transition: .2s ease opacity;

  .icon {
    display: block;
  }

  .media-card-folder:hover & {
    opacity: 1;
  }
}