@import 'assets/styles/_variables.scss';

.form__block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $spacing-large;
  width: 100%;
  box-sizing: border-box;

  > * {
    width: 100%;
  }

  &--inline {
    justify-content: initial;

    & > * {
      width: auto;
      margin-right: $spacing;
      align-self: flex-end;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--split > * {
    width: calc(50% - #{$spacing});
    box-sizing: border-box;
  }

  &--split-inline > * {
    width: auto;
  }

  &--no-spacing {
    margin: 0;
  }

  &--flatten {
    margin-bottom: 0;
  }

  &--end {
    align-self: flex-end;
    margin-bottom: $spacing-huge * -1;
    padding-bottom: $spacing-huge;
  }

  &--start {
    align-self: flex-start;

    & > * {
      align-self: flex-start;
    }
  }

  &--align-right {
    justify-content: flex-end;
  }

  &--error {
    color: $cl-red;
    margin-top: -20px;
    margin-left: $spacing-small;
  }

  &--spacing{
    &-small {
      & > * {
        margin-right: $spacing;
      }
    }
  }
}