@import 'assets/styles/_variables';

.arrangement-media-block {
  &__title {
    margin-top: 0;
  }

  &__time-between-images {
    margin-top: 20px;
    width: 212px;
  }
}