@import 'assets/styles/_variables';

.devices-card {
  padding: $spacing-large;

  &__state {
    align-items: center;
    color: $cl-grey-medium;
    display: flex;
    font-size: $font-size-small;
    min-height: 36px;
    padding-right: 36px + 8px;
  }

  &__title {
    font-size: $font-size-xlarge;
    margin-top: 0;
  }

  &__button {
    bottom: $spacing-medium;
    position: absolute;
    right: $spacing-medium;
  }
}

