@import 'assets/styles/_variables';

.arrangement-layout {
  color: $cl-grey-border;
  display: block;
  max-width: 100%;
  height: 140px;

  &--bottom-margin {
    margin-bottom: 20px;
  }
}
