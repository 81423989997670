@import 'assets/styles/_variables';

.account-settings-two-factor {
  &__fields-container {
    background-color: $cl-white;
    border-radius: 6px;
    box-shadow: 0px 6px 10px -2px rgba($cl-black, .3);
    text-align: center;
    padding: 30px 40px;
  }

  &__icon {
    margin: 30px auto;
    display: block;
  }

  &__next-button {
    margin-top: 20px;
  }

  &__verification-icon {
    vertical-align: middle;
    margin-right: 5px;
  }
}
