@import 'assets/styles/_variables';

.sub-navigation {
  background: $cl-grey-bg;
  position: sticky;
  margin-top: $spacing;
  padding-top: 2px;
  top: -2px;
  z-index: 10;

  &__links {
    display: flex;
    line-height: 24px;
    margin: 0;
    padding: $spacing 0;

    li {
      list-style: none;
    }
  }

  &__title {
    display: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: -4px;
    margin-right: $spacing-huge;
  }

  &__link {
    color: $cl-grey-dark;
    cursor: pointer;
    margin-right: $spacing-huge;
    position: relative;
    text-decoration: none;

    &--active {
      color: $cl-grey-black;
      cursor: default;

      &::after {
        background: $cl-purple;
        border-radius: 3px;
        bottom: -26px;
        content: '';
        height: 6px;
        left: 0;
        position: absolute;
        right: 0;
      }
    }

    &--disabled {
      cursor: not-allowed;
      color: $cl-grey-disabled;
    }
  }

  &--stuck {
    border-bottom: 1px solid $cl-grey;

    .sub-navigation__title {
      display: block;
    }
  }
}