@import 'assets/styles/_variables';

.drag-switch {
  display: inline-flex;
  cursor: pointer;
  background-color: $cl-white;
  border-radius: 6px;
  position: relative;
  transition: background-color 0.1s ease-in;
  box-shadow: 0 6px 10px -2px rgba($cl-black, .3);
  padding: 4px 5px;

  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:focus + .drag-switch {
      background-color: orange;
    }
  }

  &__toggle {
    background-image: linear-gradient(90deg, rgba($cl-white, .1), rgba($cl-black, .1));
    background-position: 100% center;
    background-blend-mode: multiply;
    position: absolute;
    border-radius: 5px;
    height: 41px;
    top: 4px;
    left: 5px;
    transition: all .2s ease;

    &--active {
      background-color: $cl-green;
    }

    &--inactive {
      background-color: $cl-grey-border;
    }

    &--no-animation {
      transition: none;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 41px;
    color: $cl-sigma-cool-blue;
    padding: 0 10px;
    cursor: pointer;
    transition: color 0.1s ease-in;
    white-space: nowrap;
    position: relative;

    & + & {
      margin-left: 10px;
    }

    &--off {
      color: $cl-grey-dark;
    }
  }

  &--changed {
    border: 2px solid orange;
  }

  &--checked {
    .drag-switch__label--on {
      color: $cl-white;
    }

    .drag-switch__label--off {
      color: $cl-sigma-cool-blue;
    }
  }

  &--disabled {
    background-color: $cl-grey-100;
    box-shadow: none;

    .drag-switch__toggle {
      background-color: $cl-grey-border;
      background-image: none;
    }

    .drag-switch__label {
      cursor: not-allowed;
      color: $cl-grey-dark;
    }
  }

  &--alternate-toggle {
    .drag-switch__toggle {
      &--active,
      &--inactive {
        background-color: $cl-blue;
      }
    }

    .drag-switch__label {
      &--off{
        color: $cl-white;
      }
    }

    &.drag-switch--checked {
      .drag-switch__label--on {
        color: $cl-white;
      }

      .drag-switch__label--off {
        color: $cl-sigma-cool-blue;
      }
    }

    &.drag-switch--disabled {
      .drag-switch__toggle {
        background-color: $cl-grey-border;
      }

      .drag-switch__label {
        color: $cl-grey-dark;
      }
    }
  }
}


