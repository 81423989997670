@import 'assets/styles/_variables';

.form__dropdown {
  & &__value-container--is-multi {
    flex-wrap: nowrap;
    padding-right: $spacing-large;

    .form__dropdown__input {
      display: none;
    }
  }

  & &__menu-list--is-multi {
    .form__dropdown__option {
      position: relative;
      padding: 10px $spacing-small 10px 40px;

      &::before {
        content: '';
        border: 2px solid $cl-grey-border;
        border-radius: 4px;
        display: inline-block;
        height: 16px;
        margin-bottom: -4px;
        margin-right: 10px;
        margin-left: -30px;
        width: 16px;
      }

      &::after {
        background: transparent;
        border-radius: 2px;
        content: '';
        display: block;
        height: 12px;
        left: 14px;
        position: absolute;
        top: 14px;
        transition: background .2s ease;
        width: 12px;
      }

      @include hover {
        &::after {
          background: $cl-grey-border;
        }
      }

      &--is-focussed {
        &::after {
          background: $cl-blue;
        }
      }

      &--is-selected {
        &::after {
          background: $cl-blue;
        }

        @include hover {
          &::after {
            background: $cl-blue;
          }
        }
      }
    }
  }

  & &__multi-value {
    background: none;
    margin: 0;

    &__label {
      padding: 0;
    }

    &__remove {
      display: none;
    }

    + .form__dropdown__multi-value {
      &::before {
        content: ',';
        padding-right: 4px;
      }
    }
  }

  &__selected{
    &-values {
      margin: -5px;

      &__container {
      }

      &__label {
        display: block;
        margin: 20px 0 10px;
      }
    }
    &-value {
      margin: 5px;
    }
  }
}
