@import 'assets/styles/_variables';

.media-filter {
  margin-top: $spacing-large;

  &__bar {
    display: flex;
    width: 100%;

    label:first-child {
      margin-right: 20px;
      width: 100%;
    }
  }

  &__input {
    align-items: flex-end;
    display: flex;
    flex-wrap: nowrap;
    margin-right: 50px;
    width: 100%;
  }

  &__options {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    min-width: 180px;
    text-align: right;
  }

  &--in-pool {
    margin: 0 0 $spacing-large 0;
  }

  &__option {
    margin-right: $spacing;
  }
}