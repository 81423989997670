@import 'assets/styles/_variables';

.deck-empty {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: $cl-grey-medium;

  &__title {
    color: $cl-grey-medium;
  }

  &__content {
    > * {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}