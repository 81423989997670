@import 'assets/styles/_variables';

.user-details {
  &__layout {
    display: flex;
    margin-top: -100px;
    padding-bottom: $spacing-large;

    &-column {
      flex: 1;
      margin-left: 30px;

      &:nth-child(1) {
        flex: 360px;
        margin-left: 0;
      }
    }
  }

  &__card {
    padding: 30px;
    width: 100%;
    margin-bottom: 30px;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__field {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__divider {
    border: 0;
    border-top: 1px solid $cl-grey-bg;
    margin: 30px -30px;
  }

  &__edit-button {
    justify-content: space-between;
  }

  &__back-link {
    margin-top: 30px;
  }

  &__main-header {
    padding: 30px 0 130px;

    display: grid;
    grid-gap: 30px;
    row-gap: 5px;
    justify-content: start;
    grid-template-areas:
            "icon heading"
            "icon status";
    background-color: $cl-grey;

    &__header {
      grid-area: heading;
      min-width: 300px;
    }

    &__icon {
      margin-top: 5px;
      grid-area: icon;
    }

    &__status {
      grid-area: status;
      min-width: 250px;
    }
  }
}
