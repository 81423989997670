@import 'assets/styles/_variables';

$border-width: 16px;

.arrangement-layout-area {
  &__main {
    &--interactive {
      &:hover {
        .arrangement-layout-area--rect {
          fill-opacity: 15%;
        }
      }
    }
  }

  &__text {
    color: $cl-black;

    &--interactive {
      cursor: pointer;

      &-active {
        color: $cl-purple;
      }

      &-inactive {
        color: $cl-blue;
      }
    }
  }

  &--rect {
    fill: transparent;
    stroke: currentColor;
    stroke-width: $border-width;
    transition: color .2s linear, outline-color .2s linear;
  }

  &--empty {
    fill: transparent;
    stroke: currentColor;
    stroke-width: $border-width;
    stroke-dasharray: 40, 40;
    transition: color .2s linear, outline-color .2s linear;
  }

  &--background {
    fill: transparent;

    &--interactive {
      stroke-width: $border-width;
    }
  }

  &--interactive {
    fill-opacity: 5%;
    cursor: pointer;

    &-active {
      stroke: $cl-purple;

      &:not(.arrangement-layout-area--background) {
        fill: $cl-purple;
      }
    }

    &-inactive {
      stroke: $cl-blue;

      &:not(.arrangement-layout-area--background) {
        fill: $cl-blue;
      }
    }
  }
}