@import 'assets/styles/_variables';

.arrangement-media {
  &__block {
    &--disabled {
      opacity: .4;
      pointer-events: none;
    }
  }

  .button {
    padding-right: 0;
  }
}