@import 'assets/styles/_variables';

.input-signal-edit {
  &__header-layout{
    display: flex;
    align-items: center;

    &__input {
      width: 100%;
    }

    &__drag-switch {
      margin-left: $spacing-huge;
    }
  }
}