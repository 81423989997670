@import 'assets/styles/_variables';

.priority {
  &__toggle {
    border: 2px solid transparent;

    &--active {
      border-color: $cl-blue;
      box-shadow: none;

      @include hover {
        box-shadow: none;
      }
    }

    &__name {
      padding-left: $spacing-small;
    }

    .icon svg {
      height: 28px;
    }
  }

  &--pending {
    .priority__toggle--active {
      border-color: $cl-orange;
    }
  }

  &--disabled {
    .priority__toggle {
      cursor: not-allowed;
      opacity: .5;
      box-shadow: none;

      &--active {
        opacity: 1;
      }
    }
  }
}