@import 'assets/styles/_variables';

.popup-context {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 $spacing;

    &--flatten {
      margin-bottom: 0;
    }
  }

  &__list-item {
    display: flex;
    margin-top: $spacing;

    &:first-child {
      margin-top: 0;
    }
  }
}