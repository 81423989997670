@import 'assets/styles/_variables';

.card {
  background: $cl-white;
  overflow: hidden;
  position: relative;

  &--style {
    &-normal {
      border-radius: 15px;
      padding: 40px $spacing-large $spacing-medium $spacing-large;
    }

    &-compact {
      border-radius: 4px;
      padding: $spacing-small $spacing $spacing-small $spacing - 4px;
    }
  }

  &--shadow {
    box-shadow: 0px 4px 10px rgba($cl-black, .3);
  }

  &--highlight-on-hover {
    transform: translateZ(0);
    transition: box-shadow .2s ease;
    will-change: box-shadow;

    @include hover {
      box-shadow: 0px 4px 10px rgba($cl-black, .3);
    }
  }

  &--interactive {
    transform: translateZ(0);
    transition: box-shadow .2s ease;
    will-change: box-shadow;
    box-shadow: 0px 2px 6px rgba($cl-black, .2);
    cursor: pointer;
  }

  // TODO: possibly move to a more global stylesheet
  &__button {
    bottom: $spacing-medium;
    position: absolute;
    right: $spacing-medium;
  }

  &__state {
    align-items: center;
    color: $cl-grey-medium;
    display: flex;
    font-size: $font-size-small;
    min-height: 36px;
    padding-right: 36px + 8px;
  }

  &--zoom {
    transition-property: box-shadow, transform;

    @include hover {
      transform: scale(1.08);
    }
  }
}