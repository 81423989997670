button {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  text-align: left;
  
  &:disabled {
    cursor: not-allowed;
  }
}

[type=button] {
  -webkit-appearance: none;
}