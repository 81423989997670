@import 'assets/styles/_variables';

.detail-page {
  padding: 60px 0 0 0;
  position: relative;

  .detail-button {
    bottom: 50px;
    position: fixed;
    right: 50px;
    z-index: 50;
  }
}

.detail-page__content {
  padding-bottom: $spacing-huge;
  padding-top: $spacing-huge;
}

.detail-page__cancel-button {
  bottom: 50px;
  position: fixed;
  right: 50px;
  z-index: 50;
}