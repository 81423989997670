@import 'assets/styles/_variables';

.heading {
  &--h1 {
    font-size: 46px;
    line-height: 54px;
    margin: 0;
    padding: 0;
  }

  &--h2 {
    font-size: $font-size-huge;
    line-height: 30px;
    margin: 0 0 $spacing;
    padding: 0;
  }

  &--h4 {
    font-size: $font-size-normal;
    margin: 0 0 14px;
  }

  &--no-margin {
    margin: 0;
  }
}