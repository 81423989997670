@import 'assets/styles/_variables';
@import 'components/popup/_popupVariables';

.popup {
  &__background {
    background: rgba($cl-grey-black, .75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 60;
  }

  &__wrapper {
    &__close {
      background-color: $cl-white;
      content: '';
      cursor: pointer;
      height: 27px;
      mask: url('/icons/icon_cross.svg') no-repeat center;
      position: absolute;
      right: -27px - $spacing-large;
      top: 20px;
      width: 27px;
    }

    &--small {
      padding: 10px;
    }
  }

  &__content {
    border-radius: $popupBorderRadius;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{$popupTopBottomMargin});
    max-width: 780px;
    overflow-y: visible;
    position: fixed;
    width: 100%;
    z-index: 70;

    &--small {
      width: 380px;
    }

    &--large {
      max-width: 940px;
    }

    &--position {
      &-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &-top {
        left: 0;
        right: 0;
        top: 0;
        margin: $popupTopMargin auto;
      }
    }
  }

  &--default {
    &__wrapper {
      overflow-y: auto;
      &--background {
        padding: $spacing-huge 80px;
      }
    }

    &__content {
      &--background {
        background: $cl-grey-light;
      }
    }
  }

  &--sticky{
    &__form {
      max-height: calc(100vh - #{$popupTopBottomMargin});
    }

    &__wrapper {
      max-height: calc(100vh - #{$popupTopBottomMargin});
    }

    &__header {
      background-color: $cl-white;
      border-radius: $popupBorderRadius $popupBorderRadius 0 0;
      top: 0;
      left: 0;
      right: 0;
      padding: 30px 80px;

      fieldset {
        margin-bottom: 0;
      }
    }

    &__main {
      background-color: $cl-grey-100;
      height: 100%;
      margin-bottom: $popupStickyFooterHeight;
      position: relative;
      overflow-y: auto;
      z-index: 80;

      &-shadow {
        &-top {
          position: sticky;
          height: 0;
          top: 0;
          z-index: 1;

          &:before {
            top: 0;
            box-shadow: inset 0 10px 10px -10px rgba($cl-black, .3);
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            height: 10px;
          }
        }

        &-bottom {
          position: sticky;
          height: 0;
          bottom: 0;

          &:after {
            bottom: 0;
            box-shadow: inset 0 -10px 10px -10px rgba($cl-black, .3);
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            height: 10px;
          }
        }
      }

      &--padding {
        padding: $popupStickyMainTopPadding 80px 0;

        &::after {
          content: '';
          display: block;
          height: $popupStickyMainBottomPadding;
        }

        .popup--sticky__main-shadow-top {
          &:before {
            margin-top: -$popupStickyMainTopPadding;
            margin-left: -80px;
            margin-right: -80px;
          }
        }

        .popup--sticky__main-shadow-bottom {
          &:after {
            margin-bottom: -$popupStickyMainBottomPadding;
            margin-left: -80px;
            margin-right: -80px;
          }
        }
      }

      &-inner {
        fieldset {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__footer {
      height: $popupStickyFooterHeight;
      position: absolute;
      background-color: white;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 80px;
      z-index: 1;
      display: flex;
      align-items: center;
      border-radius: 0 0 $popupBorderRadius $popupBorderRadius;
    }
  }
}