@import "_mixins";

@import "components/popup/_popupVariables";

$font-family-roboto: "Roboto", sans-serif;

$container-width: 1024px;

$cl-white: #ffffff;
$cl-grey-light: #f8f9fb;
$cl-grey-bg: #edeef0;
$cl-grey: #e4e5e6;
$cl-grey-100: #e2e8e9;
$cl-grey-300: #abb9ba;
$cl-grey-border: #cbd5d6;
$cl-grey-border-300: #abb9ba;
$cl-grey-medium: #7d8080;
$cl-grey-dark: #646666;
$cl-grey-black: #323333;
$cl-grey-disabled: #afb3b3;
$cl-black: #000000;
$cl-purple: #812990;
$cl-red: #d0021b;
$cl-orange: #ff901b;
$cl-blue: #0095da;
$cl-sigma-cool-blue: #75888a;
$cl-blue-gradient: #0087c9;
$cl-green: #7ed321;

$font-size-small: 14px;
$font-size-normal: 16px;
$font-size-large: 19px;
$font-size-xlarge: 22px;
$font-size-huge: 26px;
$font-size-xhuge: 34px;

$spacing-tiny: 8px;
$spacing-small: 16px;
$spacing: 20px;
$spacing-medium: 25px;
$spacing-large: 30px;
$spacing-huge: 50px;
$spacing-xhuge: 60px;
