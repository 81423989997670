@import 'assets/styles/_variables';

.button-select-show {
  color: $cl-blue;
  background: $cl-white;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px 10px -2px rgba($cl-black, .3);
  padding-left: $spacing;
  padding-right: $spacing;
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  box-sizing: border-box;

  &--selected {
    display: flex;
    height: 120px;
    padding: $spacing;

    &--changed {
      border: 2px solid #FF901B;
    }

    &__show-layout {
      height: 70px;

      &-container {
        width: 120px;
        margin-right: $spacing;
      }
    }

    &__text-container {
      margin-right: 20px + $spacing;
    }

    &__show-name {
      font-weight: bold;
      color: $cl-black;
      margin-bottom: 10px;
    }

    &__show-state {
    }

    &__button-delete {
      position: absolute;
      top: $spacing;
      right: $spacing;
    }
  }

  &--disabled {
    color: $cl-grey-border;
    box-shadow: none;
  }

  &__text {
    padding-left: 35px;
    padding-right: 35px;
  }

  &__prefix {
    position: absolute;
    left: 20px;
    top: 12px;
  }

  &__suffix {
    position: absolute;
    top: 12px;
    right: 20px;
    color: $cl-grey-300;
  }
}