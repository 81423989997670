@import 'assets/styles/_variables';

.trigger {
  &__header-layout {
    display: flex;
    align-items: center;

    &__input {
      width: 100%;
    }

    &__drag-switch {
      margin-left: $spacing-huge;
    }
  }

  &__label {
    position: relative;
    margin-left: -42px;
    padding-left: 42px;
    display: block;

    &-icon {
      position: absolute;
      top: -5px;
      left: 0;
      background-color: $cl-grey-100;
    }
  }

  &__form-block {
    padding-left: 42px;

    &--dotted-line {
      position: relative;

      &:before {
        border-left: 2px dashed #CBD5D6;
        content: '';
        position: absolute;
        top: 0;
        bottom: -30px;
        width: 2px;
        left: 11px;
        z-index: -1;
      }
    }
  }
}