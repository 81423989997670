@import 'assets/styles/_variables';

.media-page {
  .detail-button {
    bottom: 50px;
    position: fixed;
    right: 50px;
    z-index: 50;
  }

  &__content {
    padding-bottom: $spacing-huge;
    padding-top: $spacing-huge;
  }

  &__search-label {
    font-size: $font-size-normal;
    color: $cl-grey-black;
    opacity: .5;
    margin: 0 0 10px 0;
  }

  &__search-title {
    font-size: $font-size-xhuge;
    margin-bottom: $spacing-large;
    width: 100%;
  }
}
