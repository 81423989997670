@import 'assets/styles/_variables';

$button-min-size: 68px;
$button-min-size-small: 36px;
$button-interaction: '.button__interaction';

.button {
  box-sizing: border-box;
  box-shadow: 0 4px 10px -2px rgba($cl-black, .5);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  line-height: 28px;
  min-height: $button-min-size;
  min-width: $button-min-size;
  outline: none;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  transition: .2s ease;
  transition-property: background, box-shadow, color;
  white-space: nowrap;
  -webkit-appearance: none !important;

  @include hover($button-interaction) {
    box-shadow: 0 6px 12px -2px rgba($cl-black, .5);

    .button__hover-text {
      max-width: 400px;
      transition-delay: 0s;
    }

    .button__hover-spacing {
      transition-delay: 0s;
      width: 20px;
    }
  }

  &:active {
    box-shadow: 0 4px 10px -2px rgba($cl-black, .5);
  }

  &:disabled {
    background: $cl-grey-disabled;
    box-shadow: none;
    cursor: not-allowed;
  }
}

// Button elements

.button__text {
  align-items: center;
  align-self: center;
  display: flex;
  min-width: 28px;
}

.button__hover-text {
  display: inline-block;
  max-width: 0;
  overflow: hidden;
  transition: max-width .3s ease;
  transition-delay: .2s;
  text-align: right;
}

.button__hover-spacing {
  display: inline-block;
  width: 0;
  transition: width .1s ease;
  transition-delay: .4s;
}

.button--shape-edges {
  &-rounded {
    border-radius: 35px;
  }

  &-square {
    border-radius: 6px;
  }
}

// Button sizes
.button--size {
  &-inline {
    padding: 0;
    min-height: 0;
    min-width: 0;

    .button__text {
      min-width: 0;
    }
  }

  &-extra-small {
    padding: 4px 8px;
    min-height: $button-min-size-small;
    min-width: $button-min-size-small;
  }

  &-small {
    padding: 4px 8px;
    min-height: $button-min-size-small;
    min-width: $button-min-size-small;

    .button__text {
      min-width: 16px;
    }
  }

  &-medium-small {
    border-radius: 6px;
    min-height: 30px;
    padding-bottom: 0;
    padding-top: 0;
  }

  &-medium {
    min-height: 50px;
    padding-bottom: 0;
    padding-top: 0;
  }

  &-large {
    min-height: 60px;
    padding-bottom: 0;
    padding-top: 0;
    font-size: $font-size-large;
  }
}

// Button additional styles

.button--no-shadow {
  box-shadow: none;

  @include hover($button-interaction) {
    box-shadow: none;
  }
}

.button--text-inverse {
  flex-direction: row-reverse;
}

.button--block {
  width: 100%;
}

// Button schemes

.button--scheme {
  &-default {
    background: linear-gradient(90deg, $cl-blue, $cl-blue-gradient);
    color: $cl-white;

    &:disabled {
      color: $cl-grey-dark;
    }
  }

  &-card {
    background: $cl-grey-border;
    color: $cl-grey-black;

    @include hover($button-interaction) {
      background: $cl-purple;
      color: $cl-white;
    }
  }

  &-delete-link,
  &-link {
    background: none;
    padding: 0;
    border-radius: 0;
    min-width: auto;
    color: $cl-blue;

    @include hover($button-interaction) {
      text-decoration: underline;
    }

    &:disabled {
      background: none;
    }
  }

  &-delete-link {
    color: $cl-red;
  }

  &-delete-link--disabled,
  &-link--disabled {
    color: $cl-grey-disabled;
    cursor: not-allowed;
  }
}

.button--color {
  &-red {
    background: $cl-red;
  }
  &-green {
    background: $cl-green;
  }
  &-white {
    background: $cl-white;

    .button__text {
      color: $cl-grey-black;
    }

    &:disabled {
      background: $cl-grey-border;
      color: $cl-grey-dark;

      .button__text {
        color: $cl-grey-dark;
      }
    }
  }
}