@mixin hover($parent: '') {
  @if $parent != '' {
    #{$parent}:hover &,
    #{$parent}:active &,
    #{$parent}:focus & {
      @content;
    }
  }

  &:hover,
  &:active,
  &:focus {
    @content;
  }
}
