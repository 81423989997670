@import 'assets/styles/_variables';

.icon-button {
  min-height: unset;

  &--without-text {
    &:disabled {
      background: none;
      color: $cl-grey-disabled;
    }
  }

  &__icon {
    &,
    svg {
      display: flex;
      align-self: center;
    }

    &--after-text-margin {
      margin-left: 10px;
    }

    &--before-text-margin {
      margin-right: 10px;
    }
  }

  &--icon-after-text {
    flex-direction: row-reverse;
  }

  &--size{
    &-extra-small {
      line-height: unset;
    }

    &-small {
      padding: 10px;
      line-height: unset;
    }

    &-medium {
      font-size: $font-size-normal;
      min-height: 50px;
      padding-bottom: 0;
      padding-top: 0;
    }

    &-extra-large {
      font-size: $font-size-xlarge;
    }
  }
}