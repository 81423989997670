@import 'assets/styles/_variables';

.device-filter {
  margin-top: $spacing-large;

  &--in-pool {
    margin: 0 0 $spacing-large 0;
  }

  &__bar {
    display: flex;
    width: 100%;
  }

  &__input {
    margin-right: 70px;
    width: 100%;
  }

  &__options {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    min-width: 260px;
    text-align: right;
  }

  &__option {
    margin-right: $spacing;
  }

  &__advanced {
    margin-top: $spacing-huge;

    &__option {
      margin-right: $spacing;
    }
  }
}