@import 'assets/styles/_variables';

.icon {
  display: inline-block;

  &--block {
    img, svg {
      display: block;
    }
  }

  &--size {
    &-x-small {
      width: 16px;
      height: 16px;
    }

    &-small {
      width: 24px;
      height: 24px;
    }

    &-medium {
      width: 28px;
      height: 28px;
    }

    &-large {
      width: 34px;
      height: 34px;
    }

    &-x-large {
      width: 48px;
      height: 48px;
    }
  }

  &--color {
    &-green {
      color: $cl-green;
    }

    &-grey {
      color: $cl-grey-border;
    }

    &-orange {
      color: $cl-orange;
    }

    &-red {
      color: $cl-red;
    }

    &-blue {
      color: $cl-blue;
    }

    &-sigma-cool-blue {
      color: $cl-sigma-cool-blue;
    }
  }
}
