@import 'assets/styles/_variables';

.arrangement-media-item {
  background: $cl-white;
  border: 2px solid $cl-grey-border;
  border-radius: 6px;
  padding: $spacing-small 10px;
  position: relative;
  margin-bottom: 6px;
  transition: .2s ease;
  transition-property: box-shadow, top, transition;
  word-break: break-word;

  &--added {
    &-enter {
      &:after{
        opacity: 0;
      }
    }

    &-exit {
      &:after{
        opacity: 1;
      }
    }

    &-enter,
    &-exit {
      top: 0;

      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0px 4px 10px -2px rgba($cl-black, .6);
      }
    }

    &-enter-active {
      top: -4px;
      transform: scale(1.02);

      &:after{
        opacity: 1;
      }
    }

    &-exit-active {
      top: 0;

      &:after{
        opacity: 0;
      }
    }

    &-enter-active,
    &-exit-active {
      transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);

      &:after{
        transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }
  }

  &--disabled {
    border: 2px solid $cl-grey-disabled;
    color: $cl-grey-disabled;
  }

  &--changed {
    border: 2px solid $cl-orange;
    color: $cl-grey-disabled;
  }

  &--deleted {
    border: 2px solid $cl-red;
    color: $cl-grey-disabled;
  }

  &--is-dragging {
    box-shadow: 0px 4px 10px -2px rgba($cl-black, .6);
  }

  &__drag-and-drop {
    position: absolute;
    left: $spacing-small;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    color: $cl-grey-border;
  }

  &__name {
    margin-right: 30px;

    &--drag-and-droppable {
      margin-left: 30px;
    }
  }

  &__delete {
    position: absolute;
    right: $spacing-small;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
}