@import "assets/styles/_variables";

.info-bar {
  background: $cl-grey-black;
  border-radius: 10px 10px 0 0;
  bottom: 0;
  color: $cl-white;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  left: 0;
  padding: $spacing;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 80;

  &--normal {
    max-width: 622px;
  }

  &--wide {
    max-width: 858px;
  }

  &__icon {
    align-items: center;
    background: $cl-green;
    border-radius: 50%;
    display: flex;
    height: 36px;
    justify-content: center;
    margin-right: $spacing-small;
    min-width: 36px;
    align-self: center;

    &--info {
      background: $cl-sigma-cool-blue;
    }

    &--error {
      background: $cl-red;
    }

    &--warning {
      background: $cl-orange;
    }

    .icon {
      display: inline-flex;

      svg {
        margin: auto;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__text {
    align-self: center;
    flex: 1;

    > *:first-child {
      margin-top: 0;
    }
  }

  &__action {
    align-self: center;
    padding: 0 10px;
    margin-left: 10px;

    &--link {
      background: none;
      color: $cl-blue;
      min-width: auto;

      + .info-bar__action {
        margin-left: $spacing;
      }
    }
  }
}
