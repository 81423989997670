@import 'assets/styles/_variables';

/* autoprefixer grid: on */
@mixin columnLoop($i) {
  // card placement for IE
  @for $j from 1 through $i {
    .card:nth-child(#{$i}n+#{$j}) {
      grid-column: auto;
      -ms-grid-column: $j;
    }

    // allows for 10 rows of cards
    @for $k from 1 through 10 {
      .card:nth-child(#{($k * $i) - ($i - $j)}) {
        grid-row: auto;
        -ms-grid-row: #{$k};
      }
    }
  }
}

.deck {
  display: grid;
  margin-bottom: $spacing-huge;

  /* autoprefixer: ignore next */
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @for $i from 1 through 4 {
    &--columns-#{$i} {
      grid-template-columns: repeat($i, minmax(250px, 1fr));

      @include columnLoop($i);
    }
  }
}

