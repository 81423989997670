@use "sass:math";
@import 'components/popup/_popupVariables';

$arrangementColumnSpacing: 60px;

.arrangement {
  &__left-column {
    flex: 0.584;
    overflow-y: auto;
    max-height: calc(100vh - #{$popupStickyContentHeight});
    padding: $popupStickyMainTopPadding math.div($arrangementColumnSpacing, 2) $popupStickyMainBottomPadding 80px;
  }
  &__right-column {
    flex: 1;
    overflow-y: auto;
    max-height: calc(100vh - #{$popupStickyContentHeight});
    padding: $popupStickyMainTopPadding 80px $popupStickyMainBottomPadding math.div($arrangementColumnSpacing, 2);
  }
}