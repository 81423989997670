@import 'assets/styles/_variables';

.color-picker {
  position: relative;
  flex: initial;

  &__button {
    background: #fff;
    display: inline-block;
    cursor: pointer;
    height: 100%;
    width: 100px;
    border-radius: 6px;
    overflow: hidden;

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__context {
    position: absolute;
    z-index: 3;
  }

  &__picked-color {
    height: 100%;
  }

  &__cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__popover {
    position: absolute;
    z-index: 100;

    &-inner {
      position: fixed;
    }
  }
}
