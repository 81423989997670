.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}


.toggle-enter {
  opacity: 0;
}
.toggle-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.toggle-exit {
  opacity: 1;
}
.toggle-exit-active {
  opacity: 0;
  transition: opacity 0ms;
}


.fixed-bottom-enter {
  transform: translateY(100%);
}
.fixed-bottom-enter-active {
  transform: translateY(0);
  transition: transform 300ms;
}
.fixed-bottom-exit {
  transform: translateY(0);
}
.fixed-bottom-exit-active {
  transform: translateY(100%);
  transition: transform 300ms;
}