@import "assets/styles/_variables";

.media-card {
  cursor: pointer;
  padding: 0;
  transition: box-shadow 0.2s ease, transform 0.4s ease;

  &__content {
    box-sizing: border-box;
    min-height: 88px;
    padding: $spacing 70px $spacing $spacing-large;
  }

  &__title {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &--url span {
      word-break: break-all;
    }
  }

  &--selected {
    box-shadow: 0 0 0 3px $cl-green;

    @include hover {
      box-shadow: 0 0 0 3px $cl-green;
      transform: scale(1);
    }
  }

  &--disabled {
    cursor: initial;
    opacity: 0.5;

    @include hover {
      transform: scale(1);
    }

    .button {
      @include hover {
        background: $cl-grey-border;
        color: $cl-grey-black;
        cursor: initial;
      }
    }
  }
}
