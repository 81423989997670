@import 'assets/styles/_variables';

.login__lost-credentials-links {
  margin-top: 10px;
}

.login__lost-credentials-link {
  &:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }

  border-left: 1px solid $cl-grey-border;
  margin-left: 20px;
  padding-left: 20px;
}