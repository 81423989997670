@import 'assets/styles/_variables';

.button-group {
  & > * {
    margin-left: $spacing;

    &:first-child {
      margin-left: 0;
    }
  }
}
