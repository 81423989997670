@import "assets/styles/_variables";

.arrangement-card {
  align-items: end;
  display: grid;
  grid-template-rows: 180px auto 58px;

  &__layout {
    align-self: start;

    &--center {
      align-self: center;
    }
  }

  &__title {
    margin-bottom: 10px;
    align-self: start;
  }

  &--selected {
    box-shadow: 0 0 0 3px $cl-green;

    @include hover {
      box-shadow: 0 0 0 3px $cl-green;
      transform: scale(1);
    }
  }

  &--disabled {
    cursor: initial;
    opacity: 0.5;

    @include hover {
      transform: scale(1);
    }

    .button {
      @include hover {
        background: $cl-grey-border;
        color: $cl-grey-black;
        cursor: initial;
      }
    }
  }
}
