@import 'assets/styles/_variables';

ul, ol {
  &.label-value-list {
    line-height: 22px;
    margin: 0 0 $spacing 0;

    &--flatten {
      margin-bottom: 0;

      li:last-child {
        margin-bottom: 0;
      }
    }
    &--reset {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: $spacing-tiny;
      }
    }

    &--bullets {
      padding-left: 20px;
    }
  }
}

.list__item {
  &--loading {
    display: flex;

    .list__item-label {
      min-width: 40px;
    }

    .list__item-value {
      min-width: 200px;
      margin-left: 5px;
    }
  }
}