.public-body {
  align-items: flex-start;
  min-height: 100vh;
  display: flex;
  background-image: url(../../../../src/assets/Background.svg);
  background-repeat: no-repeat;
  background-position: center;

  &__container {
    margin: 175px auto 50px;
    max-width: 380px;
    width: 100%;
  }
}
