@import 'assets/styles/_variables';

.account-menu {
  &__icon {
    position: relative;
    color: $cl-grey-black;

    &--disabled {
      color: $cl-grey-disabled;
    }
  }

  &__menu {
    right: 0;
    top: 63px;
  }

  &--active {
    &:after {
      background: $cl-purple;
      bottom: -21px;
      border-radius: 3px;
      content: '';
      height: 6px;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}