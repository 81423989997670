@import "assets/styles/_variables";

.folder-header {
  display: flex;
  flex-wrap: wrap;

  &--margin {
    margin-bottom: $spacing-large;
  }

  &__folder-name {
    margin-right: 15px;
  }

  &__folder-button {
    font-size: 16px;
    margin-bottom: 15px;
    color: $cl-blue;
  }

  &__options {
    margin: -15px 0 15px auto;
  }
}
