@keyframes drop-shadow {
  0% {
    box-shadow: 0px 4px 10px -2px rgba($cl-black, .6);
    top: -4px;
    transform: scale(1.02);
  }
  100% {
    box-shadow: 0px 0px 0px;
    top: 0;
    transform: scale(1);
  }
}