@import 'assets/styles/_variables';

.color-picker-input {
  flex: auto;
  width: 100px;

  &__container {
    display: flex;
  }

  &__picker {
    margin-left: $spacing;

    &-popover {
      top: 50px;
    }

    &-button {
      box-shadow: 0px 6px 10px -2px rgba($cl-black, .3);
    }
  }
}