@import 'assets/styles/_variables';

.password-validator {
  margin-top: $spacing;
  font-size: $font-size-small;
  text-align: center;

  &__validators {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  &__validator {
    position: relative;
    padding-left: 25px;
    margin-left: $spacing;

    &:first-child {
      margin-left: 0;
    }

    &:before {
      content: "";
      width: 15px;
      height: 15px;
      background-color: $cl-grey-border;
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -8px;
    }

    &--valid {
      &:before {
        background-color: $cl-green;
      }
    }
  }
}

