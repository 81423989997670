@import 'assets/styles/_variables';

.media-card__image {
  background: $cl-white;
  height: 140px;
  position: relative;
  z-index: -1;
  overflow: hidden;

  &::after {
    background: transparent;
    bottom: 0;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: background .2s ease;
  }

  svg {
    height: 40px;
    width: auto;
  }

  // preview image
  img {
    display: block;
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  // normal text
  span {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  @include hover('.media-card') {
    &::after {
      background: rgba($cl-grey-black, .0);
    }
  }

  &--icon {
    img, svg {
      height: 40px;
      width: auto;
    }
  }
}