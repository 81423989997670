@import 'assets/styles/_variables';

.popup-context {
  position: relative;

  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 60;
  }

  &__content {
    background: $cl-white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba($cl-black, .3);
    min-width: 300px;
    padding: $spacing-large;
    position: absolute;
    z-index: 61;
  }
}