@import 'assets/styles/_variables';

.big-form {
  &__fields-container {
    margin-top: 80px;
    background-color: $cl-white;
    border-radius: 6px;
    box-shadow: 0px 6px 10px -2px rgba($cl-black, .3);
  }

  &__field:nth-child(n+2) {
    border-top: 1px solid $cl-grey-bg;
  }

  &__title {
    text-align: center;
    margin: 40px 0 -40px;
  }

  &__submit-button {
    margin-top: 20px;
  }

  &__footer-text {
    margin-top: 50px;
    text-align: center;
  }
}