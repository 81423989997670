@import 'assets/styles/_variables';

.trigger-card {
  padding: $spacing-large;
  align-items: end;
  display: grid;
  grid-template-rows: auto auto 36px;

  &__button {
    display: contents;
  }

  &__title {
    margin: 0 0 $spacing-large;
    align-self: start;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__status {
    margin-left: 4px;
  }

  .card__button {
    bottom: $spacing-large;
    right: $spacing-large;
  }
}