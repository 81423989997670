@import 'assets/styles/_variables';

.page-filter-container {
  background: $cl-grey;
  padding-bottom: $spacing-huge;
  padding-top: $spacing-huge;

  &--no-padding {
    padding: unset;
  }
}