@import 'assets/styles/_variables';

.input-device-edit {
  &__input-signals-header-layout {
    display: grid;
    column-gap: $spacing;
    grid-template-areas:
            "header switch"
            "paragraph switch";
    grid-template-columns: 1fr auto;
    align-items: start;

    &__header {
      grid-area: header;
    }

    &__paragraph {
      grid-area: paragraph;
    }

    &__drag-switch {
      grid-area: switch;
    }
  }
}