@import 'assets/styles/_variables';

.calendar {

  .fc-button {
    &-group {
      border: 2px solid $cl-grey-border;
      border-radius: 8px;
      box-sizing: border-box;
      height: 50px;
      padding-left: 4px;
      padding-right: 4px;
    }

    .fc-icon {
      display: none;
    }
  }

  .fc-button-primary {
    background: none;
    border: none;
    color: $cl-purple;
    font-weight: 500;

    &.fc-button-active {
      background: none;

      &:not(:disabled):focus {
        box-shadow: none;
      }
    }

    &:focus {
      &, &:active {
        background: none;
        border: none;
        box-shadow: none;
        color: $cl-purple;
        outline: none;
      }
    }
  }

  // view buttons
  .fc-timeGridWeek-button,
  .fc-listWeek-button {
    color: $cl-grey-border;
    font-size: 0;
    width: 48px;

    &.fc-button-active::before {
      background-color: $cl-purple;
    }

    &::before {
      background-color: $cl-grey-border;
      content: '';
      display: inline-block;
      height: 20px;
      width: 20px;
    }
  }

  .fc-timeGridWeek-button {
    &::before {
      mask: url('/icons/icon_calendar.svg') no-repeat center;
    }
  }

  .fc-listWeek-button {
    &::before {
      mask: url('/icons/icon_list.svg') no-repeat center;
    }
  }

  // button specifically for 'today'
  .fc-button.fc-today-button {
    color: $cl-black;
    font-weight: normal;

    &:active, &:focus {
      color: $cl-black;
    }
  }

  .fc-prev-button {
    &::before {
      background-color: $cl-purple;
      content: '';
      color: $cl-purple;
      display: block;
      height: 14px;
      mask: url('/icons/icon_arrow-left.svg');
      width: 18px;
    }
  }

  .fc-next-button {
    &::before {
      background-color: $cl-purple;
      content: '';
      color: $cl-purple;
      display: block;
      height: 14px;
      mask: url('/icons/icon_arrow-right.svg');
      width: 18px;
    }
  }

  // toolbar above table
  .fc-toolbar {

    // title in the toolbar
    h2 {
      border: 2px solid $cl-grey-border;
      border-radius: 8px;
      box-sizing: border-box;
      font-size: $font-size-large;
      height: 50px;
      padding: 0 12px;
      line-height: 46px;
    }

    // each button group
    & > * > :not(:first-child) {
      margin-left: $spacing;
    }
  }

  // row of the header
  .fc-head-container {
    border-bottom: 1px solid $cl-grey-dark;
  }

  // cell in header for date
  .fc-day-header {
    color: $cl-grey-dark;
    font-weight: normal;
    font-size: $font-size-normal;
    padding: 10px 0 20px 5px;
    text-align: left;
  }

  // body of the table
  .fc-timeGridWeek-view .fc-scroller {
    border: 2px solid $cl-grey-border;
    border-radius: 8px;
  }

  // body of rows
  .fc-slats {
    td {
      height: 60px;
    }
  }

  .fc-axis {
    border-right: 0;
  }

  .fc-widget-header {
    border: 0;
  }

  // column for today
  td.fc-today {
    background: none;
  }

  // first background column
  .fc-mon {
    border-left: 0;
  }

  // each half hour row (6:30, 7:30, etc)
  .fc-minor {
    border-bottom: 1px solid $cl-grey;
  }

  // content cell
  .fc-widget-content {
    border-top: 0; // border top was hiding the fc-minor border bottom
    border-left: 0;
    background: none;
  }

  // last column with border
  .fc-body > tr > .fc-widget-content {
    border-right: 0;
  }

  // time cell
  .fc-time {
    background: $cl-grey-bg;
    font-size: $font-size-small;
    text-align: center;
    transform: translateY(-10px);
    vertical-align: top;
  }

  // horizontal line for the current time
  .fc-now-indicator {
    border-color: $cl-purple;
    border-top-width: 2px;

    // arrow aligned with the line
    &-arrow {
      height: 2px;
      border-width: 0px !important;
      margin: 0;
      z-index: 10;

      &::before {
        background: $cl-grey-bg;
        content: var(--time);
        color: $cl-purple;
        display: block;
        font-size: $font-size-small;
        height: 14px;
        transform: translateY(-50%);
        pointer-events: none;
        text-align: center;
        width: 43px;
      }
    }
  }

  // parent of now indicator
  .fc-content-col {
    position: unset; // relative position kept the now indicator in one day
  }

  // event card
  .fc-time-grid-event {
    background: $cl-white;
    border: 0px solid $cl-white;
    border-radius: 4px;
    box-shadow: inset 2px 0px 0px $cl-blue, 0px 2px 6px 0px rgba($cl-black, .3);
    color: $cl-grey-black;
    font-size: $font-size-small;
    padding: 10px;

    // !important to overwrite the inline style on each event
    left: 0 !important;
    right: 0 !important;

    // overlapping events
    &.fc-time-grid-event-inset {
      left: 4px !important;
    }

    &.fc-short {
      .calendar__event__title {
        display: none;
      }
    }

    // time label inside an event card
    .fc-time {
      display: none;
      transform: none;
    }
  }

  // actual event
  .calendar__event {
    cursor: pointer;
    margin: 3px 0;

    &--inactive {
      box-shadow:
        inset 0px 0px 0px 2px $cl-grey-disabled,
        inset 4px 0px 0px 0px $cl-grey-disabled,
        0px 2px 6px 0px rgba($cl-black, 0.3);;
    }

    &--empty {
      display: none;
    }

    &--has-icons {
      min-height: 16px;

      .fc-content {
        height: 100%;
        min-height: 16px;
      }

      .fc-title {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
    }

    &__icons {
      background: $cl-white;
      bottom: 0;
      height: 16px;
      position: absolute;
      width: 100%;

      .icon {
        height: 16px;
        margin-right: 5px;

        svg {
          height: 16px;
        }
      }
    }
  }

  // actual event in the past
  .calendar__event--past {
    opacity: .5;
  }

  .calendar__event--pending {
    box-shadow: inset 2px 0px 0px $cl-orange,
      0px 2px 6px 0px rgba($cl-black, .3);
    border: 2px solid $cl-orange;
  }

  // make the current time bigger to hide the timestamp
  &--overlapping-time {
    .fc-now-indicator-arrow {
      &::before {
        align-items: center;
        display: flex;
        height: 60px;
        justify-content: center;
      }
    }
  }

  // list styling
  // ------------

  .fc-listWeek-view {
    border: none;
    margin: 0 -10px;

    .fc-scroller {
      padding: 10px;
    }

    .fc-list-table {
      border-collapse: separate;
      border-spacing: 0 4px;
    }
  }

  // no events message
  .fc-list-empty-wrap2 {
    display: none;
  }

  // heading of each day
  .fc-list-heading {
    font-size: $font-size-xlarge;
    width: 240px;

    &:first-child .fc-widget-header {
      background: none;
      padding-top: 0;
    }

    .fc-widget-header {
      padding: 50px 0 8px 0;
    }

    .fc-list-heading-main,
    .fc-list-heading-alt {
      float: none;
    }

    .fc-list-heading-alt {
      padding-left: 8px;
    }
  }

  // event item
  .fc-list-item {
    line-height: 20px;
    position: relative;

    // random dot that is not needed
    &-marker {
      display: none;
    }

    &-time,
    &-title {
      float: left;
      padding: $spacing $spacing-small;
    }

    &-time {
      color: $cl-grey-dark;
      width: 160px;
    }

    &-title {
      color: $cl-grey-black;
      padding-right: 96px;
      position: relative;
      width: calc(100% - 304px);

      &::before {
        background: $cl-white;
        bottom: 0;
        border-left: 4px solid $cl-blue;
        border-radius: 4px;
        box-shadow: 0px 4px 10px -2px rgba($cl-black, .3);
        box-sizing: border-box;
        content: '';
        left: -192px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
      }

      .calendar__event__icons {
        bottom: 0;
        right: 10px;
        top: $spacing;
        width: auto;

        svg {
          height: 20px;
          margin-left: 5px;
        }
      }
    }

    @include hover {
      td {
        background: none;
      }
    }

    &.calendar__event {
      &--inactive {
        box-shadow: none;
      }

      &--inactive {
        box-shadow: none;

        .fc-list-item-title::before {
          box-shadow: inset 2px 0px 0px 0px $cl-grey-disabled,
            0px 2px 4px 0px rgba($cl-black, .3);
          border: 2px solid $cl-grey-disabled;
          border-radius: 4px;
        }
      }

      &--pending {
        box-shadow: none;

        .fc-list-item-title::before {
          box-shadow:
            inset 2px 0 0 $cl-orange,
            0px 2px 4px 0px rgba($cl-black, .3);
          border: 2px solid $cl-orange;
          border-radius: 4px;
        }
      }

      &--empty {
        display: block;

        .fc-list-item-time {
          font-size: 0;
          padding: 0;
          width: 0;
        }

        .fc-list-item-title {
          padding: 0;
          opacity: .5;

          &::before {
            content: none;
          }
        }
      }
    }
  }

  &__container {
    position: relative;

    &--loading {
      .fc-view-container {
        opacity: 0;
      }
    }
  }

  &__skeleton-list {
    padding-top: 10px;
    position: absolute;
    top: 74px;
    width: 100%;

    &::after {
      background-image: linear-gradient(180deg, rgba($cl-grey-bg, 0), $cl-grey-bg);
      bottom: 0;
      content: '';
      height: 180px;
      left: 0;
      position: absolute;
      right: 0;
    }

    &__table {
      margin-bottom: $spacing-large;
      margin-top: $spacing-small;
      width: 100%;
    }

    &__item {
      background: $cl-white;
      border-radius: 4px;
      display: flex;
      margin-bottom: 5px;

      & > div {
        padding: $spacing + 4px $spacing-small;
      }
    }

    &__time {
      width: 160px;
    }
  }

  &__skeleton-calendar {
    position: absolute;
    top: 74px;
    width: 100%;

    &__head {
      align-items: center;
      display: flex;
      height: 48px;
      padding-left: 44px;

      > div {
        width: 100%;
      }
    }

    &__body {
      border: 2px solid $cl-grey-border;
      border-radius: 8px;
      height: 714px;
      overflow: hidden;
      position: relative;
    }

    &__columns,
    &__rows {
      bottom: 0;
      display: flex;
      left: 44px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__rows {
      flex-direction: column;
    }

    &__column {
      border-left: 1px solid #DDD;
      width: 100%;

      &:first-child {
        border-left: 0;
      }
    }

    &__row {
      border-top: 1px solid #DDD;
      flex-shrink: 0;
      height: 120px;

      &:first-child {
        height: 40px;
        border-top: 0;
      }
    }

    &__times {
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: 6px;
      position: absolute;
      top: 32px;
    }

    &__time {
      flex-shrink: 0;
      height: 121px;
    }

    &__events {
      height: 100%;
      left: 44px;
      position: absolute;
      right: 0;
    }

    &__event {
      height: 292px;
      margin-left: 3px;
      margin-top: 4px;
      position: absolute;
      width: calc(calc(100% / 7) - 6px);

      .card {
        border-radius: 2px;
        height: 100%;
        padding: 10px;
      }

      &__button {
        bottom: 10px;
        position: absolute;
        width: 16px;
      }
    }
  }
}