@import 'assets/styles/_variables';

.icon-list {
  margin-bottom: $spacing;
  position: relative;
  overflow: hidden;

  &--single-item {
    .icon-list__item {
      &::before {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    overflow: hidden;

    &--icon {
      &-size {
        &-x-small {
          &::before {
            left: 7px;
            bottom: 6px;
          }
        }

        &-small {
          &::before {
            left: 11px;
            bottom: 6px;
          }
        }
      }
    }

    &--loading {
      &::before {
        display: none;
      }
    }

    &::before {
      border-left: 2px dashed $cl-grey-border;
      content: '';
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:first-child {
      &::before {
        top: 4px;
      }
    }

    &:last-child {
      &::before {
        height: 25px;
      }
    }

    &-icon {
      color: $cl-blue;
      position: relative;
      overflow: hidden;
      margin-right: 10px;

      &--size {
        &-x-small {
          top: 2px;
        }
      }

      svg {
        position: relative;
      }

      &--white {
        svg {
          background: $cl-white;
        }
      }

      &--grey {
        svg {
          background: $cl-grey-bg;
        }
      }
    }

    &-text {
      flex: 1;
      line-height: 24px;

      &--single-line {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    & + & {
      padding-top: $spacing;
    }
  }
}
