fieldset,
.fieldset {
  border: 0;
  padding: 0;
  margin: 0 0 $spacing-huge 0;

  legend {
    font-size: $font-size-huge;
    font-weight: 500;
    margin-bottom: $spacing;

    & + p {
      margin-top: -10px;
    }
  }

  &--flatten {
    margin-bottom: $spacing-large;
  }

  &--small {
    margin-bottom: $spacing-small;
  }
}

.form {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__error {
    color: $cl-red;
    margin-top: $spacing-tiny;
    margin-bottom: 0;
    padding: 0 $spacing-small;
    box-sizing: border-box;

    &--wide {
      padding: 0;
    }
  }

  &__container-error {
    border-radius: 0 1px 1px 0;
    bottom: 12px;
    background: $cl-red;
    left: 0;
    position: absolute;
    top: 12px;
    width: 2px;
  }
}
